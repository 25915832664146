<template>
  <el-form-item >
    <el-upload
        class="elUpload"
        :action="action"
        name="file"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        >

        <div ><el-button size="small" type="none" >上传</el-button></div> 
    </el-upload>
  </el-form-item>
</template>

<script>
import {getToken} from '@/utils/auth.js'
export default {
    name:"ImportExcel",
    // :action="https://api.leshop.cloud/book/import"
    props:{
        action:{
            type:String,
            require:true
        }
    },
    data(){
        return{
            headers:{
        "Authorization": getToken()  //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJiaWQiOjEsImFpZCI6MSwiYWNjb3VudCI6ImxldHUxMjM0IiwidXNlcm5hbWUiOiLnqIvmoLnno4ogICJ9.AkDHUs_6NXASjfwNqc1G9pB-eCO5ZFiSoSQK_5WBLeU",
      },
        }
    },
    methods:{
        beforeAvatarUpload(file) {
            console.log(file.type)
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||  file.type === "application/vnd.ms-excel";
           
            if (!isExcel) {
            this.$message.error('只能上传excel工作表格哦!');
            }
            
            return isExcel 
        },
        handleAvatarSuccess(res, file) {
            
            if(res.code == 200 && res.data != "ERROR"){
                this.$message("上传成功");
            }else{
                this.$message(res.data =="ERROR" ? "excel表格数据异常" : "上传成功");
            }
           
        },
    
    }

}
</script>

<style  >
.ceshi{
    height: 100px;
}
  
</style>